import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import '../styles/global.css';

import DirtLayout from '../components/DirtLayout';
import Seo from '../components/Seo';

export default function Equipment() {
   return (
      <DirtLayout>
         <Seo title='Equipment' />
         <EquipmentHeader />
         <div className='container px-5 py-24 mx-auto'>
            <div className='flex flex-col text-center w-3/4 mx-auto'>
               <h1 className='sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900'>Meet the Hunter Environmental Fleet</h1>
               <p className='lg:w-2/3 mx-auto leading-relaxed text-base border-t-4 border-dirt pt-2'>
                  Here are a few of the many pieces of the Hunter Environmental fleet that help us get the job done.
               </p>
            </div>
            <div className='max-w-7xl mx-auto mt-16'>
               <div className='max-w-2xl mx-auto lg:max-w-none'>
                  <div className='mt-6 space-y-8 lg:space-y-6 lg:grid lg:grid-cols-3 lg:gap-x-6'>
                     <div className='relative mt-6 shadow-lg'>
                        <div className='relative group rounded-lg overflow-hidden '>
                           <StaticImage
                              alt='Hunter Environmental Skid steer plowing walkway'
                              src='../images/Hunter-Environmental-Walkway-Management-Header.jpg'
                              className='relative h-80 w-full object-center object-cover'
                           />
                           <div
                              aria-hidden='true'
                              className='bg-gradient-to-b from-transparent to-black opacity-80 md:opacity-0 md:group-hover:opacity-80 absolute inset-0 transition duration-300 ease-in-out'
                           />
                           <div className='absolute inset-0 p-6 flex items-end opacity-100 md:opacity-0 md:group-hover:opacity-100 transition duration-300 ease-in-out'>
                              <div>
                                 <h3 className='font-semibold text-snow-light'>
                                    <span className='relative inset-0' />
                                    HE Equipment
                                 </h3>
                                 <p aria-hidden='true' className='mt-1 text-sm text-white'>
                                    Skid-steer Loader
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='relative shadow-lg'>
                        <div className='relative group rounded-lg overflow-hidden '>
                           <StaticImage
                              alt='Hunter Environmental Excavator'
                              src='../images/Hunter-Environmental-Excavation-Header-Alt-2.jpg'
                              className='relative h-80 w-full object-center object-cover'
                           />
                           <div
                              aria-hidden='true'
                              className='bg-gradient-to-b from-transparent to-black opacity-80 md:opacity-0 md:group-hover:opacity-80 absolute inset-0 transition duration-300 ease-in-out'
                           />
                           <div className='absolute inset-0 p-6 flex items-end opacity-100 md:opacity-0 md:group-hover:opacity-100 transition duration-300 ease-in-out'>
                              <div>
                                 <h3 className='font-semibold text-dirt'>
                                    <span className='relative inset-0' />
                                    HE Equipment
                                 </h3>
                                 <p aria-hidden='true' className='mt-1 text-sm text-white'>
                                    Excavator
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='relative shadow-lg'>
                        <div className='relative group rounded-lg overflow-hidden '>
                           <StaticImage
                              alt='Hunter Environmental Truck'
                              src='../images/Hunter-Environmental-Equipment-Header.jpg'
                              className='relative h-80 w-full object-center object-cover'
                           />
                           <div
                              aria-hidden='true'
                              className='bg-gradient-to-b from-transparent to-black opacity-80 md:opacity-0 md:group-hover:opacity-80 absolute inset-0 transition duration-300 ease-in-out'
                           />
                           <div className='absolute inset-0 p-6 flex items-end opacity-100 md:opacity-0 md:group-hover:opacity-100 transition duration-300 ease-in-out'>
                              <div>
                                 <h3 className='font-semibold text-snow-light'>
                                    <span className='relative inset-0' />
                                    HE Equipment
                                 </h3>
                                 <p aria-hidden='true' className='mt-1 text-sm text-white'>
                                    Snow Fighters
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='relative shadow-lg'>
                        <div className='relative group rounded-lg overflow-hidden '>
                           <StaticImage
                              alt='Hunter Environmental Front End Loader'
                              src='../images/Hunter-Environmental-Equipment-Front-End-Loader.jpg'
                              className='relative h-80 w-full object-center object-cover'
                           />
                           <div
                              aria-hidden='true'
                              className='bg-gradient-to-b from-transparent to-black opacity-80 md:opacity-0 md:group-hover:opacity-80 absolute inset-0 transition duration-300 ease-in-out'
                           />
                           <div className='absolute inset-0 p-6 flex items-end opacity-100 md:opacity-0 md:group-hover:opacity-100 transition duration-300 ease-in-out'>
                              <div>
                                 <h3 className='font-semibold text-dirt'>
                                    <span className='relative inset-0' />
                                    HE Equipment
                                 </h3>
                                 <p aria-hidden='true' className='mt-1 text-sm text-white'>
                                    Front-End Loaders
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='relative shadow-lg'>
                        <div className='relative group rounded-lg overflow-hidden '>
                           <StaticImage
                              alt='Hunter Environmental Plow truck'
                              src='../images/Hunter-Environmental-Snow-Plowing-Feature-4.jpg'
                              className='relative h-80 w-full object-center object-cover'
                           />
                           <div
                              aria-hidden='true'
                              className='bg-gradient-to-b from-transparent to-black opacity-80 md:opacity-0 md:group-hover:opacity-80 absolute inset-0 transition duration-300 ease-in-out'
                           />
                           <div className='absolute inset-0 p-6 flex items-end opacity-100 md:opacity-0 md:group-hover:opacity-100 transition duration-300 ease-in-out'>
                              <div>
                                 <h3 className='font-semibold text-snow-light'>
                                    <span className='relative inset-0' />
                                    HE Equipment
                                 </h3>
                                 <p aria-hidden='true' className='mt-1 text-sm text-white'>
                                    Plows
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className='relative shadow-lg'>
                        <div className='relative group rounded-lg overflow-hidden '>
                           <StaticImage
                              alt='Hunter Environmental Salt Spreader'
                              src='../images/Hunter-Environmental-Deicing-Header.jpg'
                              className='relative h-80 w-full object-center object-cover'
                           />
                           <div
                              aria-hidden='true'
                              className='bg-gradient-to-b from-transparent to-black opacity-80 md:opacity-0 md:group-hover:opacity-80 absolute inset-0 transition duration-300 ease-in-out'
                           />
                           <div className='absolute inset-0 p-6 flex items-end opacity-100 md:opacity-0 md:group-hover:opacity-100 transition duration-300 ease-in-out'>
                              <div>
                                 <h3 className='font-semibold text-snow-light'>
                                    <span className='relative inset-0' />
                                    HE Equipment
                                 </h3>
                                 <p aria-hidden='true' className='mt-1 text-sm text-white'>
                                    Salt Spreaders
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </DirtLayout>
   );
}

const EquipmentHeader = () => {
   return (
      <div className='grid'>
         <StaticImage
            style={{ gridArea: '1/1' }}
            layout='fullWidth'
            alt='Hunter Environmental Truck'
            src='../images/Hunter-Environmental-Equipment-Header.jpg'
            formats={['auto', 'webp', 'avif']}
            className='h-128'
         />
         <div aria-hidden='true' style={{ gridArea: '1/1' }} className='bg-gradient-to-b from-black opacity-80 relative inset-0 z-10' />
         <div className='grid relative items-center w-full z-20' style={{ gridArea: '1/1' }}>
            <div className='w-full h-fit'>
               <div className='flex flex-col justify-center items-center md:items-start max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:px-0'>
                  <div className='text-4xl lg:text-6xl text-center md:text-left md:pl-2 my-6 md:border-l-8 md:border-dirt font-bold text-white'>
                     <motion.h1
                        initial={{ opacity: 0, y: -15 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className='mb-4 text-center md:text-left'
                     >
                        HE
                     </motion.h1>
                     <motion.h1 initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                        Equipment
                     </motion.h1>
                  </div>

                  <AnchorLink
                     to='/dirt#contact'
                     style={{ fontFamily: 'Muli' }}
                     className='w-max md:ml-4 shadow-lg mt-8 bg-dirt hover:bg-dirt-light  text-xs md:text-base rounded-md text-black px-12 py-2'
                  >
                     Contact Us
                  </AnchorLink>
               </div>
            </div>
         </div>
      </div>
   );
};
